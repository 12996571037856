<template>
  <div>
    <div class="flex-align" v-if="item.file">
      <img class="svg-sub3" src="/static/icon/u_paperclip.svg" style="margin-right:12px">
      <div class="file" @click="clickFile">{{ item.file }}</div>
    </div>
    <div class="download-modal">
      <sweet-modal overlay-theme="dark" ref="downloadModal" width="564px"
                   :hide-close-button="true" :enableMobileFullscreen="false">
        <div class="subtitle4">파일을 다운로드하시겠습니까?</div>
        <div class="flex-between body5-medium" style="padding:64px 16px 0;">
          <button class="button body2-bold" style="width:50%;height:52px" @click="$refs.downloadModal.close()">취소
          </button>
          <button class="button is-primary margin-left-16 body2-bold"
                  style="width:50%;height:52px;"
                  @click="clickDownload">확인
          </button>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>

<script>
  import {SweetModal} from 'sweet-modal-vue'

  export default {
    name: "TalkFile",
    components: {
      SweetModal
    },
    props: {
      item: {
        type: Object
      }
    },
    created() {

    },
    data() {
      return {}
    },
    methods: {
      clickFile() {
        this.$refs.downloadModal.open();
      },
      clickDownload() {
        const a = document.createElement("a");
        a.href = this.item.file;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);
        this.$refs.downloadModal.close();

        /*delete axios.defaults.headers.common.Authorization
        axios({
          method: 'get',
          url: this.item.file,
          responseType: 'blob'
        }).then(res=>{
          const blob = res.data;
          let file = this.item.file.split('/');
          file = file[file.length-1].split('.');
          const name = file[0];
          const ext = file[1];
          if (window.navigator.msSaveOrOpenBlob) { // for IE
            window.navigator.msSaveOrOpenBlob((blob), `${name}.${ext}`);
          } else { // Others
            const reader = new FileReader();
            reader.onloadend = () => {
              const a = document.createElement("a");
              a.href = reader.result;
              a.style.display = 'none';
              a.setAttribute('download', `${name}.${ext}`);
              document.body.appendChild(a);
              a.click();
              a.parentNode.removeChild(a);
            }
            reader.readAsDataURL(blob);
          }
        });*/
      },
    }
  }
</script>

<style lang="stylus" scoped>
  .file
    word-break break-all
    color #333333
    cursor pointer
    text-decoration underline

  .svg-sub3
    filter invert(52%) sepia(21%) saturate(15%) hue-rotate(28deg) brightness(94%) contrast(88%)
</style>

<style lang="stylus">
  .download-modal .sweet-modal.is-mobile-fullscreen
    top 50% !important
    left 50% !important
    transform translate(-50%, -50%) !important

  .download-modal .sweet-content
    padding-bottom 12px !important

  @media (min-width: 501px)
    .download-modal .sweet-modal
      height 250px

    .download-modal .sweet-content
      padding-top 72px !important

  @media (max-width: 500px)
    .download-modal .sweet-modal
      max-width 80vw !important
      height 200px

      .subtitle4
        font-size 14px

      .body2-bold
        font-size 13px

      .button
        height 36px !important

    .download-modal .sweet-content
      padding-top 58px !important

</style>
